// 限时拼团
<template>
  <el-row v-if="goodList.length>0">
    <el-col :span="24">
      <div class="zone">
        <div class="HeaderTit">
          <h3>限时拼团</h3>
          <span class="subhead1">
            省钱秘笈拼团购
          </span>
          <div class="moreInfo" @click="jumpProductType">更多</div>
        </div>
        <el-row :span="24" :gutter="20" style="margin: 0;background:#fff">
          <FlashGroupLi v-for="(item,index) in  goodList" :key="index" :info="item">
          </FlashGroupLi>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="less" scoped>
  @import "~style/index.less";

  .zone {
    overflow: hidden;

    .HeaderTit {
      margin-top: 30px;
      padding: 14px 20px;
      background: #fff;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;

      h3 {
        float: left;
        margin-left: 10px;
        margin-right: 12px;
        color: #333;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
      }

      .subhead1 {
        color: #F56C28;
        font-size: 13px;
        margin-bottom: -6px;
      }

      .subhead2 {
        color: #CF03F9;
        font-size: 13px;
        margin-bottom: -6px;
      }

      .moreInfo {
        position: absolute;
        right: 20px;
        cursor: pointer;

        &:hover {
          color: #ff3333;
        }

        .fonts();
        color: #666;
      }
    }

    ul {
      width: 228px;
      float: left;

      li {
        height: 292px;
        margin-top: 15px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .shopInfo {
      float: left;
      width: calc(100% - 228px);

      .item {
        margin-right: 0px !important;
        margin-left: 15px;
      }

      .item:nth-child(4n + 0) {
        margin-right: 0px;
      }
    }
  }
</style>

<script>
  const FlashGroupLi = () => import('components/index/FlashGroupLi.vue');
  import {
    flashGroupList
  } from "api/home.js";
  export default {
    name: "FlashGroup",
    data() {
      return {
        titMsg: "",
        subTitle: '',
        goodList: []
      };
    },
    created() {
       this.titMsg = '限时拼团'
        this.subTitle = '限时拼团'
        this.flashGroup()
    },
    methods: {
      //获取限时拼团列表
      async flashGroup() {
        const datas = {}
        const result = await flashGroupList(datas)
        if (result.data.code === 200) {
          this.goodList = result.data.data.records.slice(0,5)
        } else {
          this.goodList = []
        }
      },
      jumpProductType() {
        //限时拼团
        this.$router.push({
            name: "FlashGroupList",
          })
       
      },

    },
    components: {
      FlashGroupLi
    },
  };
</script>